<template>
  <button
    class="mawj-btn-custom"
    :style="{
      backgroundColor: type === 'primary' ? '#586e84' : '',
      color: type === 'primary' ? '#fff' : '',
      borderColor: noBorder ? '#fff' : '#586e84',
    }"
    @click="$emit('click')"
  >
    {{ label }}
  </button>
</template>
<script>
export default {
  props: ["label", "type", "noBorder"],
};
</script>

<style scoped>
.mawj-btn-custom {
  font-size: 12px;
  border: 1px solid #fff;
  background: #fff;
  margin: 4px;
  border-radius: 6px;
}
</style>
