export const countries = {
    create: {
        url: 'country/create',
        method: 'POST',
        data: null
    },
    update: {
        url: 'country/update/',
        method: 'PUT',
        id: null,
        data: null
    },
    get: {
        url: 'admin/country/getAll',
        method: 'GET',
        id: null,
        params: null
    },
    delete: {
        url: 'country/delete/',
        method: 'DELETE',
        id: null
    }
}